import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import QMCloudsTietokoneRaporttiImg from '../../components/images/QMCloudsTietokoneRaporttiImg';
import LinkTo from '../../components/LinkTo';
import QualityManualRequest from '../../components/QualityManualRequest';
import urls from '../../components/urls';

export default () => {
  const { t } = useTranslation();
  return (
    <Container className="container-space">
      <Row>
        <Col lg={6}>
          <h2 className="text-blue">{t('QualitySystemIntroductinTitle')}</h2>
          <div className="line blue-line"></div>
          <p>{t('QualitySystemIntroductinP1')}</p>
          <p>{t('QualitySystemIntroductinP2')}</p>
          <p>{t('QualitySystemIntroductinP3')}</p>
        </Col>
        <Col lg={6}>
          <QMCloudsTietokoneRaporttiImg className="img-w-350" />
          <h3 className="text-blue mt-4">{t('DownloadDocumentModelTitle')}</h3>
          <QualityManualRequest />
          <div className="mt-4" />
          <LinkTo
            url={urls.qualityManualUrl}
            text={t('ReadMoreQualityManual')}
          />
        </Col>
      </Row>
    </Container>
  );
};
