import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BackgroundImage from '../../components/images/BackgroundImage';
import QMCloudsMobiiliEtusivuImg from '../../components/images/QMCloudsMobiiliEtusivuImg';
import LinkTo from '../../components/LinkTo';
import QualityManualRequest from '../../components/QualityManualRequest';
import urls from '../../components/urls';

export default () => {
  const { t } = useTranslation();
  return (
    <BackgroundImage>
      <Container className="container-space text-white">
        <Row>
          <Col lg={6} className="mb-4">
            <h2 className="text-white">{t('QualitySystemStructureTitle')}</h2>
            <div className="line white-line" />
            <p>{t('QualitySystemStructureP')}</p>
            <QMCloudsMobiiliEtusivuImg />
          </Col>
          <Col lg={6}>
            <h3 className="text-white">{t('QualitySystemStructureTitleLi')}</h3>
            <ol>
              <li className="mb-2">{t('QualitySystemStructureLi1')}</li>
              <li className="mb-2">{t('QualitySystemStructureLi2')}</li>
              <li className="mb-2">{t('QualitySystemStructureLi3')}</li>
              <li className="mb-2">{t('QualitySystemStructureLi4')}</li>
              <li className="mb-2">{t('QualitySystemStructureLi5')}</li>
              <li>{t('QualitySystemStructureLi6')}</li>
            </ol>
            <h3 className="text-white">{t('DownloadDocumentModelTitle')}</h3>
            <QualityManualRequest isBackgroundBlue />
            <LinkTo
              url={urls.qualityManualUrl}
              text={t('ReadMoreQualityManual')}
              hoverWhite
            />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
};
