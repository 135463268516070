import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import QMCloudsScreenOpastusImage from '../../components/images/QMCloudsScreenOpastusImg';
import QMCloudsTietokoneIsoImg from '../../components/images/QMCloudsTietokoneIsoImg';
import LinkTo from '../../components/LinkTo';
import QualityManualRequest from '../../components/QualityManualRequest';
import urls from '../../components/urls';

export default () => {
  const { t } = useTranslation();
  return (
    <Container className="container-space">
      <Row>
        <Col lg={6} className="mt-2 mb-4">
          <QMCloudsScreenOpastusImage className="img-w-450" />
        </Col>
        <Col lg={6}>
          <h2 className="text-blue">{t('HowToDevelopQualitySystemTitle')}</h2>
          <div className="line blue-line" />
          <p>{t('HowToDevelopQualitySystemP1')}</p>
          <p>{t('HowToDevelopQualitySystemP2')}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="mt-5 text-blue">{t('HowToDevelopQualitySystemP3')}</h3>
          <h4 className="text-blue">
            {t('HowToDevelopQualitySystemListTitle1')}
          </h4>
          <p>{t('HowToDevelopQualitySystemListP1')}</p>
          <h4 className="text-blue">
            {t('HowToDevelopQualitySystemListTitle2')}
          </h4>
          <p>{t('HowToDevelopQualitySystemListP2')}</p>
          <h4 className="text-blue">
            {t('HowToDevelopQualitySystemListTitle3')}
          </h4>
          <p>{t('HowToDevelopQualitySystemListP3')}</p>
          <h4 className="text-blue">
            {t('HowToDevelopQualitySystemListTitle4')}
          </h4>
          <p>{t('HowToDevelopQualitySystemListP4')}</p>
          <h4 className="text-blue">
            {t('HowToDevelopQualitySystemListTitle5')}
          </h4>
          <p>{t('HowToDevelopQualitySystemListP5')}</p>
          <h4 className="text-blue">
            {t('HowToDevelopQualitySystemListTitle6')}
          </h4>
          <p>{t('HowToDevelopQualitySystemListP6')}</p>
          <h4 className="text-blue">
            {t('HowToDevelopQualitySystemListTitle7')}
          </h4>
          <p>{t('HowToDevelopQualitySystemListP7')}</p>
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="mb-4">
          <h3 className="mt-4 text-blue">{t('DownloadDocumentModelTitle')}</h3>
          <QualityManualRequest />
          <div className="mt-4" />
          <LinkTo
            url={urls.qualityManualUrl}
            text={t('ReadMoreQualityManual')}
          />
        </Col>
        <Col>
          <QMCloudsTietokoneIsoImg className="img-w-450" />
        </Col>
      </Row>
    </Container>
  );
};
