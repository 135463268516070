import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import QMCloudsLaatudokumentitImage from '../../components/images/QMCloudsLaatudokumentitImage';
import QMCloudsTabletDokumenttipohjatImg from '../../components/images/QMCLoudsTabletDokumenttipohjatImg';
import LinkTo from '../../components/LinkTo';
import urls from '../../components/urls';

export default () => {
  const { t } = useTranslation();
  return (
    <Container className="container-space">
      <Row>
        <Col lg={6}>
          <h2 className="text-blue">{t('WhyToBuildQualitySystemTitle')}</h2>
          <div className="line blue-line" />
          <p>{t('WhyToBuildQualitySystemP')}</p>
        </Col>
        <Col lg={6}>
          <QMCloudsTabletDokumenttipohjatImg />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h3 className="text-blue">{t('WhyToBuildQualitySystemTitle2')}</h3>
          <ol>
            <li className="mb-3">{t('WhyToBuildQualitySystemLi1')}</li>
            <li className="mb-3">{t('WhyToBuildQualitySystemLi2')}</li>
            <li className="mb-3">{t('WhyToBuildQualitySystemLi3')}</li>
            <li className="mb-3">{t('WhyToBuildQualitySystemLi4')}</li>
          </ol>
          <LinkTo
            url={`${urls.home}#references`}
            text={t('WhyToBuildQualitySystemToReferences')}
          />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <QMCloudsLaatudokumentitImage className="img-shadow img-rounded" />
        </Col>
      </Row>
    </Container>
  );
};
