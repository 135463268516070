import React from 'react';
import Layout from '../components/layout';
import QualityManualRequestWithBg from '../components/QualityManualRequestWithBg';
import SaveMoney from '../components/SaveMoney';
import SEO from '../components/seo';
import withI18next from '../components/withI18Next';
import QmcloudsOgImg from '../images/qmclouds-og-img.png';
import HowToDevelopQualitySystem from '../pages-partials/quality-system/HowToDevelopQualitySystem';
import MaintainingQualitySystem from '../pages-partials/quality-system/MaintainingQualitySystem';
import QualitySystemHero from '../pages-partials/quality-system/QualitySystemHero';
import QualitySystemIntroduction from '../pages-partials/quality-system/QualitySystemIntroduction';
import QualitySystemStructure from '../pages-partials/quality-system/QualitySystemStructure';
import WhyToBuildQyalitySystem from '../pages-partials/quality-system/WhyToBuildQyalitySystem';

const QualitySystemPage = ({ pageContext: { locale }, t }: any) => (
  <Layout locale={locale}>
    <SEO
      title={t('QyalitySystemSeoTitle')}
      description={t('QyalitySystemSeoDescription')}
      pageImage={QmcloudsOgImg}
      pageUrl="https://qmclouds.com/quality-system"
      lang={locale}
    />
    <QualitySystemHero />
    <SaveMoney />
    <QualityManualRequestWithBg />
    <WhyToBuildQyalitySystem />
    <QualitySystemStructure />
    <QualitySystemIntroduction />
    <MaintainingQualitySystem />
    <HowToDevelopQualitySystem />
  </Layout>
);

export default withI18next({ ns: 'common' })(QualitySystemPage);
