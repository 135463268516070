import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BackgroundImage from '../../components/images/BackgroundImage';
import QMCloudsLaptopMobileImage from '../../components/images/QMCloudsLaptopMobileImg';
import LinkTo from '../../components/LinkTo';
import QualityManualRequest from '../../components/QualityManualRequest';
import urls from '../../components/urls';

export default () => {
  const { t } = useTranslation();
  return (
    <BackgroundImage>
      <Container className="container-space text-white">
        <Row>
          <Col>
            <h2 className="text-white">{t('MaintainingQualitySystemTitle')}</h2>
            <div className="line white-line" />
            <p>{t('MaintainingQualitySystemP')}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className="text-white">
              {t('MaintainingQualitySystemSubTitle1')}
            </h3>
            <p>{t('MaintainingQualitySystemSubP1')}</p>
            <h3 className="text-white">
              {t('MaintainingQualitySystemSubTitle2')}
            </h3>
            <p>{t('MaintainingQualitySystemSubP2')}</p>
            <h3 className="text-white">
              {t('MaintainingQualitySystemSubTitle3')}
            </h3>
            <p>{t('MaintainingQualitySystemSubP3')}</p>
            <h3 className="text-white">
              {t('MaintainingQualitySystemSubTitle4')}
            </h3>
            <p>{t('MaintainingQualitySystemSubP4')}</p>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <h3 className="mt-4 text-white">
              {t('DownloadDocumentModelTitle')}
            </h3>
            <QualityManualRequest isBackgroundBlue />
            <LinkTo
              url={urls.qualityManualUrl}
              text={t('ReadMoreQualityManual')}
              hoverWhite
            />
          </Col>
          <Col>
            <QMCloudsLaptopMobileImage className="img-w-450" />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
};
